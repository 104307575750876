'use client'

import { useTranslations } from 'next-intl'

import { Autocomplete, AutocompleteItem } from '@/components/autocomplete'
import { useSearch } from './hooks'
import { convertAbsoluteUrlToRelative } from '@/common/utils/url-utils'

export const HeaderSearch = () => {
  const t = useTranslations('Header')
  const { searchItems, isLoadingSearch, setQuery } = useSearch()

  const handleSubmit = (text: string) => {
    const singleItem =
      searchItems.length === 2 || searchItems[2].isTitle
        ? searchItems[1]
        : undefined

    if (singleItem?.value) {
      singleItem?.clickEvent?.()
      history.pushState({}, '', singleItem.value)
      location.reload()
    } else {
      if (text.length > 0) {
        history.pushState({}, '', `/catalogsearch/result/?q=${text}`)
        location.reload()
      }
    }
  }

  const handleSelect = (autocompleteItem: AutocompleteItem) => {
    const itemPath = autocompleteItem.value

    if (itemPath) {
      autocompleteItem?.clickEvent?.()
      try {
        new URL(itemPath)
        const relativeUrl = convertAbsoluteUrlToRelative(new URL(itemPath))
        history.pushState({}, '', relativeUrl)
      } catch (e) {
        history.pushState({}, '', itemPath)
      } finally {
        location.reload()
      }
    }
  }

  return (
    <Autocomplete
      items={searchItems}
      onChange={setQuery}
      onSubmit={handleSubmit}
      onSelect={handleSelect}
      loading={isLoadingSearch}
      className="w-full mb-2 md:mb-0"
      placeholder={t('search.placeholder')}
      placeholderFocused={t('search.placeholderFocused')}
      aria-label={t('search.placeholder')}
    />
  )
}
