'use client'

import { RefObject, useEffect } from 'react'

export const useTabAway = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
): void => {
  useEffect(() => {
    const persistendRefCurrent = ref?.current

    const handleTabAway = (event: KeyboardEvent) => {
      // If the user tabbed away (event.key is 'Tab') and the target was the monitored input field
      if (ref.current === event.target && event.key === 'Tab') {
        callback()
      }
    }

    persistendRefCurrent?.addEventListener('keydown', handleTabAway)

    return () => {
      persistendRefCurrent?.removeEventListener('keydown', handleTabAway)
    }
  }, [ref, callback])
}
