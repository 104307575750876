import { createContext, useContext } from 'react'

import { AutocompleteContext } from '../autocomplete-types'

const autocompleteContext = createContext<AutocompleteContext>(
  {} as AutocompleteContext,
)

export const AutocompleteProvider = autocompleteContext.Provider
export const useAutocompleteContext = () => {
  return useContext(autocompleteContext)
}
