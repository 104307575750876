'use client'

import { selectors } from '@/common/constants/selectors-constants'

type HighlightedTextProps = {
  text: string
  match?: string
  className?: string
  disabled?: boolean
}

export const HighlightedText: React.FC<HighlightedTextProps> = ({
  text,
  match,
  className,
  disabled,
}) => {
  const parts = text.split(new RegExp(`(${match})`, 'gi'))

  if (disabled) {
    return <span className={className}>{text}</span>
  }

  return (
    <span
      className={className}
      data-test={selectors.common.autocomplete.highlightedText}
    >
      {parts?.map((part, i) =>
        part.toLowerCase() === match?.toLowerCase() ? (
          <strong key={i}>{part}</strong>
        ) : (
          part
        ),
      )}
    </span>
  )
}
