import { SearchResultFragment } from '@/api'
import { AutocompleteItem } from '@/components/autocomplete'

export const getSearchResultsFromAutocompleteItems = (
  autocompleteItems: AutocompleteItem[],
): SearchResultFragment[] =>
  autocompleteItems.map((item, index) => ({
    serp_position: index + 1,
    uri: item.value || '',
    display_text: item.label,
  }))
