'use client'

import { HeaderSearch } from '../header-search'
import { useHeaderContext } from '../header-context'

export function SearchComponentMobile() {
  const {
    state: { isMobileSearch },
  } = useHeaderContext()

  return (
    <div
      className="hidden md:hidden py-1 transition-all data-[mobile='true']:flex w-full"
      data-mobile={isMobileSearch}
    >
      <HeaderSearch />
    </div>
  )
}
