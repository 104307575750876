'use client'

import React, { FC } from 'react'
import { useTranslations } from 'next-intl'
import { twMerge } from 'tailwind-merge'

import { Button } from '@/components/button'
import { Text } from '@/components/text'
import { useCartContext } from '@/providers'
import { CartPopupMenuItems } from './cart-popup-menu-items'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'

interface HeaderAccountPopupMenuProps {
  className?: string
}

export const HeaderCartMenu: FC<HeaderAccountPopupMenuProps> = ({
  className,
}) => {
  const t = useTranslations('Header')
  const { hasItems, cartItems } = useCartContext()

  return (
    <div
      data-test={selectors.common.card.headerAcountPopupMenu}
      className={twMerge(
        'flex flex-col justify-start min-w-[300px] p-5',
        className,
      )}
    >
      {hasItems && <CartPopupMenuItems cartItems={cartItems} />}
      {hasItems && (
        <Link
          className="flex h-12 px-2 w-fit items-center hover:bg-transparent"
          href="/checkout/cart"
        >
          <Button variant="success">{t('checkout')}</Button>
        </Link>
      )}
      {!hasItems && (
        <Text weight="bold" align="center">
          {t('emptyCart')}
        </Text>
      )}
    </div>
  )
}
