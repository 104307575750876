import { postSearchAnalyticsData } from '@/api'

interface SearchEventProps {
  searchQuery: string
  searchTimestamp: number
}

export const searchEvent = ({
  searchQuery,
  searchTimestamp,
}: SearchEventProps) =>
  postSearchAnalyticsData({
    event: 'search',
    query: {
      current_value: searchQuery,
      entered_at: searchTimestamp,
    },
  })
