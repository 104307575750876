import { ChangeEvent, FocusEvent, HTMLAttributes, KeyboardEvent } from 'react'
import { Subject } from 'rxjs'
import { EventObject } from 'xstate'

export interface AutocompleteItem {
  // unique id of the item
  id: string
  value?: string
  // label to be displayed in the dropdown
  label: string
  // image to be displayed on the left of the label
  image?: string
  // if true, the item will be rendered as a separator and not be clickable
  isTitle?: boolean
  // optional click event fired on click
  clickEvent?: () => void
}

export interface AutocompleteProps {
  // array of items to be displayed in the dropdown when the input is focused and not empty
  items: AutocompleteItem[]
  placeholder?: string
  placeholderFocused?: string
  // search value change handler
  onChange?: (value: string) => void
  // external loading state
  loading?: boolean
  // custom props for the input
  inputProps?: HTMLAttributes<HTMLInputElement>
  className?: string
  // when item in focus, enter or click will trigger this handler
  onSelect?: (value: AutocompleteItem) => void
  // when no items in focus this handler will be triggered with the current search value
  onSubmit?: (value: string) => void
}

export enum AutocompleteState {
  // the input is not focused
  IDLE = 'IDLE',
  // the input is focused, shortcutComponent is rendered, and the input is empty
  FOCUSED_EMPTY = 'FOCUSED_EMPTY',
  // the input is focused and not empty
  FOCUSED_SEARCHING = 'FOCUSED_SEARCHING',
}

export interface AutocompleteContext {
  searchValue: string
  events$: Subject<AutocompleteEvent>
  focusedItemId?: string
  items?: AutocompleteItem[]
}

export interface AutocompleteFocusItemEvent extends EventObject {
  type: 'focusItem'
  id?: string
}

export interface AutocompleteSubmitItemEvent extends EventObject {
  type: 'submit'
}

export type AutocompleteEvent =
  | KeyboardEvent<HTMLInputElement>
  | ChangeEvent<HTMLInputElement>
  | FocusEvent<HTMLInputElement>
  | AutocompleteFocusItemEvent
  | AutocompleteSubmitItemEvent
