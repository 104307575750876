import { postSearchAnalyticsData, SearchResultFragment } from '@/api'

interface ResultsEventProps {
  searchQuery: string
  searchResults: SearchResultFragment[]
  searchTimestamp: number
  resultsTimestamp: number
}

export const resultsEvent = ({
  searchQuery,
  searchResults,
  searchTimestamp,
  resultsTimestamp,
}: ResultsEventProps) =>
  postSearchAnalyticsData({
    event: 'results',
    query: {
      current_value: searchQuery,
      entered_at: searchTimestamp,
    },
    serp: {
      displayed_at: resultsTimestamp,
      search_query: searchQuery,
      results: searchResults,
    },
    offset: resultsTimestamp - searchTimestamp,
  })
