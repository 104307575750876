import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useTranslations } from 'next-intl'

import { useXsearchPopularQuery, useXsearchQuery } from '@/api'
import { AutocompleteItem } from '@/components/autocomplete'
import { clickEvent, resultsEvent, searchEvent } from '../events'
import { getSearchResultsFromAutocompleteItems } from '../utils'

const DEBOUNCE_IN_MS = 500

interface SearchOutput {
  isLoadingSearch: boolean
  searchItems: AutocompleteItem[]
  setQuery: Dispatch<SetStateAction<string>>
}
export const useSearch = (): SearchOutput => {
  const t = useTranslations('Header')

  // static popular search
  const { data: popularSearchData } = useXsearchPopularQuery()
  const popularSearchItems = useMemo(() => {
    const popularSearch =
      popularSearchData?.xsearchPopular?.map((item) => ({
        id: item?.query ?? '',
        label: item?.query ?? '',
        value: `/catalogsearch/result/?q=${item?.query}`,
        image: undefined,
      })) || []

    return [
      { id: 'popular', label: t('search.menu.popular'), isTitle: true },
      ...popularSearch,
    ]
  }, [t, popularSearchData?.xsearchPopular])

  // dynamic query search
  const [query, setQuery] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [searchTimestamp, setSearchTimestamp] = useState<number>(0)

  const searchEnabled = searchQuery.length >= 1

  const { data: searchData, isLoading } = useXsearchQuery(
    { text: searchQuery },
    { enabled: searchEnabled },
  )

  const searchItems = useMemo(() => {
    if (!searchQuery) {
      return []
    }

    const result: AutocompleteItem[] = []

    const { products, categories, brands } = searchData?.xsearch ?? {}
    const [productsItems, categoriesItems, brandsItems] = [
      products,
      categories,
      brands,
    ].map(
      (elements) =>
        elements?.map((item) => ({
          id: item?.id?.toString() ?? '',
          label: item?.name ?? '',
          value: item?.url ?? '',
          image: item?.image ?? '',
        })) || [],
    )

    if (searchQuery.length >= 3 && productsItems?.length) {
      const productsTitle = {
        id: 'product',
        label: t('search.menu.product'),
        isTitle: true,
      }
      const searchResults = getSearchResultsFromAutocompleteItems(productsItems)
      const resultsTimestamp = Date.now()

      result.push(
        productsTitle,
        ...productsItems.map((item) => ({
          ...item,
          clickEvent: () =>
            clickEvent({
              searchQuery,
              searchResults,
              searchTimestamp,
              resultsTimestamp,
              clickedItem: item,
            }),
        })),
      )
      resultsEvent({
        searchQuery,
        searchResults,
        searchTimestamp,
        resultsTimestamp,
      })
    }

    if (categoriesItems?.length) {
      const categoriesTitle = {
        id: 'categorie',
        label: t('search.menu.categories'),
        isTitle: true,
      }
      result.push(categoriesTitle, ...categoriesItems)
    }

    if (brandsItems?.length) {
      const brandsTitle = {
        id: 'brands',
        label: t('search.menu.brands'),
        isTitle: true,
      }
      result.push(brandsTitle, ...brandsItems)
    }

    return result

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchData?.xsearch, t])

  // for debounce
  useEffect(() => {
    let isFresh = true

    setTimeout(() => {
      if (isFresh) {
        setSearchQuery(query)
        if (query) {
          const newSearchTimestamp = Date.now()
          setSearchTimestamp(newSearchTimestamp)
          searchEvent({
            searchQuery: query,
            searchTimestamp: newSearchTimestamp,
          })
        }
      }
    }, DEBOUNCE_IN_MS)

    return () => {
      isFresh = false
    }
  }, [query])

  return {
    isLoadingSearch: searchEnabled && isLoading,
    searchItems: searchEnabled ? searchItems : popularSearchItems,
    setQuery,
  }
}
